import queryString from 'query-string'
import { batch } from 'react-redux'
import { toast } from 'react-toastify'

import { apiService, fetchingAPI } from '@/api'
import { setOrganization, setOrganizationAuditEvents } from './organization.redux'
import { setUsers } from './organizationUsers.redux'
import { setOrganizationTags } from './organizationTags.redux'
import { setOrganizationTagCategories } from './organizationTagCategories.redux'
import { setOrganizationCustomBranding } from './organizationCustomBranding.redux'
import { updateUserPermissions } from '../auth/currentUser.redux'
import { setCustomBranding } from '../customBranding/customBranding.redux'
import { fetchOrganizations } from './organizations.actions'
import { closeModal } from '../ui/ui.redux'
import { formatFile } from './organization.helpers'

// TODO: Refactor toggleLoading
export const fetchOrganization =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const organization = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}`,
        'GET',
        dispatch
      )
      dispatch(setOrganization(organization))
    } catch (err) {
      // catching error
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const fetchOrganizationAuditEvents =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    try {
      const organization = await fetchingAPI(
        `${apiService.web}/api/audit_events/${organizationId}`,
        'GET',
        dispatch
      )
      dispatch(setOrganizationAuditEvents(organization))
    } catch (err) {
      // catching error
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

// TODO: Refactor toggleLoading
export const fetchOrganizationData =
  ({ organizationId, toggleLoading }) =>
  async (dispatch) => {
    const configProperties = queryString.stringify({
      requested_properties: 'id,name,cid,organization_id,config_active',
    })

    const loadOrg = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}`,
      'GET',
      dispatch
    )
    const loadAllConfigs = fetchingAPI(
      `${apiService.web}/api/configs?${configProperties}`,
      'GET',
      dispatch
    )
    const loadOrgUsers = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )
    const loadOrgTags = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/tags`,
      'GET',
      dispatch
    )
    const loadOrgTagCategories = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
      'GET',
      dispatch
    )

    try {
      const [organization, configs, { users, edit_config, edit_users }, tags, tagCategories] =
        await Promise.all([
          loadOrg,
          loadAllConfigs,
          loadOrgUsers,
          loadOrgTags,
          loadOrgTagCategories,
        ])

      batch(() => {
        dispatch(setOrganization(organization))
        dispatch({ type: 'LOAD_CONFIGS', configs })
        dispatch(setUsers(users))
        dispatch(setOrganizationTags(tags))
        dispatch(updateUserPermissions({ edit_config, edit_users }))
        dispatch(setOrganizationTagCategories(tagCategories))
      })
    } catch (err) {
      // catching error
    } finally {
      if (toggleLoading) toggleLoading()
    }
  }

export const fetchOrganizationUsersAndTags =
  ({ organizationId, setLoading, modalClose }) =>
  async (dispatch) => {
    if (setLoading) setLoading(true)
    const loadOrgUsers = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )
    const loadOrgTags = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/tags`,
      'GET',
      dispatch
    )

    try {
      const [{ users, edit_config, edit_users }, tags] = await Promise.all([
        loadOrgUsers,
        loadOrgTags,
      ])

      dispatch(setUsers(users))
      dispatch(setOrganizationTags(tags))
      dispatch(updateUserPermissions({ edit_config, edit_users }))

      if (modalClose) modalClose()
    } catch (err) {
      // catching error
    } finally {
      if (setLoading) setLoading(false)
    }
  }

export const updateOrganizationProperties =
  ({ toggleLoading, organizationId, reloadOrganization, ...orgProps }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      ...orgProps,
    })
    try {
      await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}`,
        'PATCH',
        dispatch,
        body
      )

      dispatch(reloadOrganization({ organizationId, toggleLoading }))
    } catch (err) {
      // catching error
      if (toggleLoading) toggleLoading()
    }
  }

// TODO: Convert to async/await
export const generatePassphrase = (updatePassphrase) => (dispatch) =>
  fetchingAPI(`${apiService.web}/api/generate_password`, 'GET', dispatch)
    .then((passphrase) => updatePassphrase(passphrase))
    .catch((err) => console.error('generatePassphrase failed', err))

// TODO: Convert to async/await, remove alert
export const reportPassphrase = (badPassphrase) => (dispatch) => {
  fetchingAPI(
    `${apiService.web}/api/report_password`,
    'POST',
    dispatch,
    JSON.stringify(badPassphrase)
  )
    // eslint-disable-next-line no-alert
    .then((reportedPassphrase) => alert(`${reportedPassphrase} has been reported.`))
    .catch(() => {
      // catching error
    })
}

// TODO: Convert to async/await
export const generateTemporaryLink = (updateTempLinkExpiration, userData) => (dispatch) => {
  const body = JSON.stringify(userData)

  fetchingAPI(`${apiService.web}/api/reset_password`, 'POST', dispatch, body)
    .then(({ expiration, link }) => {
      updateTempLinkExpiration(expiration, link)
    })
    .catch((err) => console.error('generateTemporaryLink failed', err))
}

// TODO: Refactor alert
export const deleteDesktopTokens =
  ({ organizationId }) =>
  async (dispatch) => {
    try {
      const orgName = await fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/desktop_tokens`,
        'DELETE',
        dispatch
      )
      // eslint-disable-next-line no-alert
      alert(`All desktop tokens for ${orgName} have been deleted`)
    } catch (err) {
      // catching error
    }
  }

export const deleteOrganization =
  ({ organizationId, push }) =>
  async (dispatch) => {
    try {
      await fetchingAPI(`${apiService.web}/api/organizations/${organizationId}`, 'DELETE', dispatch)
      dispatch(fetchOrganizations())

      push('/organizations')
    } catch (err) {
      // catching error
    }
  }

export const handleFileUploadResponse = async (response) => {
  const { status } = response

  if (status > 400) {
    throw new Error()
  }
  const body = await response.json()

  if (response.status === 400) {
    const { error } = body
    throw new Error(error)
  }

  return body
}

const uploadFile = async (key, fileArr, organizationId, currentUser) => {
  const formattedFile = formatFile(key, fileArr)

  const body = new FormData()
  body.append('file', formattedFile)

  return fetch(`${apiService.web}/api/organizations/${organizationId}/files`, {
    method: 'PUT',
    // Content-Type headers breaks this request
    // that's why this is a separate fetch
    headers: {
      username: currentUser.username,
      token: currentUser.token,
      organizationid: currentUser.organizationid,
    },
    body,
  }).then(handleFileUploadResponse)
}

export const uploadCustomizationOptions =
  (
    {
      customBrandingDisplayName,
      customBrandingPrimaryColor,
      customBrandingSecondaryColor,
      faviconUrl,
      iconLightUrl,
      iconDarkUrl,
    },
    organizationId
  ) =>
  async (dispatch, getState) => {
    const { currentUser } = getState()
    const shouldUpdateCurrentUser = organizationId === currentUser.organizationid

    const customBrandingJSON = JSON.stringify({
      custom_branding_display_name: customBrandingDisplayName,
      custom_branding_theme: {
        primary_color: customBrandingPrimaryColor,
        secondary_color: customBrandingSecondaryColor,
      },
    })
    const customBrandingRequest = fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}`,
      'PATCH',
      dispatch,
      customBrandingJSON
    )
    const faviconRequest = faviconUrl
      ? uploadFile('favicon', faviconUrl, organizationId, currentUser)
      : null
    const iconLightRequest = iconLightUrl
      ? uploadFile('icon-light', iconLightUrl, organizationId, currentUser)
      : null
    const iconDarkRequest = iconDarkUrl
      ? uploadFile('icon-dark', iconDarkUrl, organizationId, currentUser)
      : null

    const pendingRequests = [
      customBrandingRequest,
      faviconRequest,
      iconLightRequest,
      iconDarkRequest,
    ]

    try {
      await Promise.all(pendingRequests)

      // Fetch the latest
      const customBranding = await fetchingAPI(
        `${apiService.web}/api/custom_branding/${organizationId}`,
        'GET',
        dispatch
      )

      dispatch(setOrganizationCustomBranding(customBranding))

      if (shouldUpdateCurrentUser) {
        dispatch(setCustomBranding(customBranding))
      }
      dispatch(closeModal())

      toast.success('Custom branding options saved!')
    } catch (err) {
      let message = 'Failed to upload custom branding options.'
      if (err?.message) {
        message += ` ${err.message}`
      }

      toast.error(message)
    }
  }
