import React from 'react'
import classNames from 'classnames'
import { Popup, Button } from 'semantic-ui-react'
import moment from 'moment'
import { startCase } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { Drawer } from '@/components/drawer/Drawer'

import { CoachingDrawer } from './CoachingDrawer'

import './TaskCard.scss'

export const TaskCard = ({
  title,
  description,
  status,
  date,
  className,
  primaryActionButtonText = 'Review',
  handlePrimaryAction,
  secondaryActionButtonText = 'Dismiss',
  handleSecondaryAction,
  ...props
}) => {
  const createdAt = moment(props.created_at)

  return (
    <div className={classNames('task-card', className)} {...props}>
      <header>
        <h2>{title}</h2>
      </header>
      {description && (
        <section>
          <p>{description}</p>
        </section>
      )}
      <footer>
        <div className="task-card__metadata">
          <div>
            <Popup
              position="top left"
              inverted
              content={createdAt.format('MMMM Do YYYY, h:mm:ss a')}
              trigger={<small>Generated {createdAt.fromNow()}</small>}
            />
          </div>
          <div className="task-card__metadata--divider" />
          <div>
            <Pill
              small
              success={status === 'completed'}
              emphasized={status === 'ready_for_review'}
              magical={status === 'collecting_evidence'}
              bold
              className="uppercase"
            >
              {startCase(status)}
            </Pill>
          </div>
        </div>
        <div className="flex-align-center small-gap">
          {handleSecondaryAction && (
            <Button type="button" basic onClick={handleSecondaryAction}>
              {secondaryActionButtonText}
            </Button>
          )}
          <Drawer
            wide
            title="Coaching Task"
            trigger={<Button secondary>{primaryActionButtonText}</Button>}
          >
            <CoachingDrawer />
          </Drawer>
        </div>
      </footer>
    </div>
  )
}
