import React, { useContext, useEffect } from 'react'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import '../charts.scss'
import { Segment } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { ExternalCallIdsLink } from '@/views/QACopilot/components/ExternalCallIdsLink'
import { PerformanceIndicatorBar } from '@/components/charts/PerformanceIndicatorBar/PerformanceIndicatorBar'
import WidgetContext from '@/components/widget/widget.context'
import { useSelector } from 'react-redux'

export const AgentPerformanceChart = ({
  description,
  columns = [],
  rows = [],
  loading,
  defaultOrderBy,
}) => {
  const { filters } = useSelector((state) => state.qaCopilot)
  const { handleChangeCsvData } = useContext(WidgetContext)

  const formattedRows = rows.map((row) => ({
    ...row,
    last_name: row.full_name.split(' ')[row.full_name.split(' ').length - 1],
    failing_percent: {
      as: `${parseFloat(100 - parseFloat(row.passing_average)).toPrecision(2)}%`,
    },
    average_score: {
      as: `${parseFloat(row.average_score)}%`,
      value: row.average_score,
    },
    pass_rate: {
      as: `${parseInt(row.passing_average, 10)}%`,
      value: row.passing_average,
    },
    passing_average: {
      as: PerformanceIndicatorBar({
        passing_average: parseFloat(row.passing_average),
      }),
    },
    passed_call_count: {
      as: !isEmpty(row.passed_calls) ? (
        <ExternalCallIdsLink
          callIds={row.passed_calls}
          label={row.passed_calls.length}
          className="muted-text small-text"
        />
      ) : (
        '0'
      ),
      value: row.passed_calls.length,
    },
    failed_call_count: {
      as: !isEmpty(row.failed_calls) ? (
        <ExternalCallIdsLink
          callIds={row.failed_calls}
          label={row.failed_calls.length}
          className="muted-text small-text"
        />
      ) : (
        '0'
      ),
      value: row.failed_calls.length,
    },
  }))

  useEffect(() => {
    // We have 1 column with no label (the green/red bar graph), so we need to filter it out
    const downloadableColumns = columns.filter((column) => !isEmpty(column.label))
    const rowsForDownload = formattedRows.map((row) => {
      row.passed_call_count = row.passed_calls.length
      row.failed_call_count = row.failed_calls.length
      row.pass_rate = row.pass_rate.value
      return row
    })

    handleChangeCsvData({
      data: rowsForDownload || [],
      headers: downloadableColumns,
      filters: { startDate: filters.startDate, endDate: filters.endDate },
      fileName: 'Agent Performance',
    })
  }, [rows])

  return (
    <div className="ranked-progress-bar-chart" data-testid="ranked-progress-bar-chart">
      {!isEmpty(description) && (
        <div className="ranked-progress-bar-chart-header">
          <h4>{description}</h4>
        </div>
      )}
      <Segment className="not-padded">
        <AdvancedTable
          rows={formattedRows}
          columns={columns}
          loading={loading}
          defaultOrder="asc"
          defaultOrderBy={defaultOrderBy}
          testid="agent-performance-table"
          index="user_id"
          borderless
          striped={false}
          pagination
        />
      </Segment>
    </div>
  )
}
