import React from 'react'
import {
  IconClipboardCheck,
  IconListCheck,
  IconPhone,
  IconSettings,
  IconTools,
  IconWorld,
} from '@tabler/icons-react'

// Sections
export const CALLS = 'calls'
export const QUALITY = 'quality'
export const GUIDANCE = 'guidance'
export const COACHING = 'coaching'
export const COMPLIANCE = 'compliance'
export const SETUP = 'setup'
export const TOOLS = 'tools'

export const iconMap = {
  [CALLS]: <IconPhone />,
  [QUALITY]: <IconListCheck />,
  [GUIDANCE]: <IconWorld />,
  [COACHING]: <IconClipboardCheck />,
  [COMPLIANCE]: <IconClipboardCheck />,
  [SETUP]: <IconSettings />,
  [TOOLS]: <IconTools />,
}

// Calls
export const callsLinks = [
  { path: '/call-explorer', label: 'Call Explorer' },
  { path: '/command-center', label: 'Live Listen' },
  { path: '/playlists', label: 'Playlists' },
]

// Quality
export const qaLinks = [
  { path: '/qa-copilot/inbox', label: 'QA Tasks' },
  { path: '/qa-copilot/dashboard', label: 'Copilot Dashboard', exact: true },
  { path: '/qa-copilot/enterprise', label: 'Copilot Enterprise Dashboard' },
  { path: '/qa-copilot/disputes', label: 'Copilot Agent Disputes' },
  { path: '/qa-copilot/view_scores', label: 'Copilot Scores' },
  { path: '/qa-copilot/activity', label: 'Copilot Activity' },

  { path: '/scorecards/dashboard', label: 'RTQ Dashboard', exact: true },
  { path: '/scorecards/disputes', label: 'RTQ Agent Disputes' },
  { path: '/scorecards/view_scores', label: 'RTQ Scores' },
  { path: '/scorecards/activity', label: 'RTQ Activity' },
]

// Coaching
export const coachingLinks = [
  { path: '/coaching/inbox', label: 'Coaching Tasks' },
  { path: '/coaching', label: 'Coaching Calls', exact: true },
]

// Guidance
export const guidanceLinks = [
  { path: '/playbooks', label: 'Playbooks' },
  { path: '/reports', label: 'Reporting Overview', exact: true },
  { path: '/reports/checklist', label: 'Checklist Reporting' },
  { path: '/reports/dynamic-prompt', label: 'Dynamic Prompt Reporting' },
  { path: '/reports/notifications', label: 'Notifications Reporting' },
  { path: '/reports/postcall', label: 'Post Call Reporting' },
  { path: '/reports/usage', label: 'Usage Reporting' },
  { path: '/leaderboards', label: 'Leaderboards' },
  { path: '/gamification/challenges', label: 'Challenges' },
  { path: '/gamification/badges', label: 'Badges' },
  { path: '/realtime_coaching/activity-log', label: 'RTC Activity Log' },
  { path: '/realtime_coaching/reports', label: 'RTC Reporting' },
]

// Compliance
export const complianceLinks = [{ path: '/compliance/inbox', label: 'Compliance Tasks' }]

// Setup
export const setupLinks = [
  { path: '/realtime-notetaker', label: 'Notetaker' },
  { path: '/qa-copilot', label: 'Copilot Scorecards', exact: true },
  { path: '/scorecards', label: 'RTQ Scorecards', exact: true },
  { path: '/coaching/scorecards', label: 'Coaching Scorecards', exact: true },
  { path: '/realtime_coaching/alerts', label: 'RTC Alerts' },
  { path: '/organizations', label: 'Organization Management' },
  { path: '/users', label: 'User Management' },
  { path: '/integrations', label: 'Integrations' },
  { path: '/keywords_repository', label: 'Keyword Repository' },
]

// Setup
export const toolsLinks = [
  { path: '/feature-feedback', label: 'Feature Feedback' },
  { path: '/reports/csv', label: 'Playbook Data Export' },
  { path: '/qa-copilot/exports', label: 'Copilot Data Export' },
  { path: '/scorecards/exports', label: 'RTQ Scorecard Data Export' },
  { path: '/realtime_coaching/exports', label: 'RTC Data Export' },
]
