import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Header, Loader, Segment } from 'semantic-ui-react'
import pluralize from 'pluralize'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { isArray, isEmpty, isString, trim } from 'lodash'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import { closeModal, openModal } from '@/reducers/ui/ui.redux'
import { fetchOrganizationCustomBranding } from '@/reducers/organizations/organizationCustomBranding.actions'
import {
  deleteDesktopTokens,
  deleteOrganization,
  fetchOrganization,
  fetchOrganizationData,
  generatePassphrase,
  generateTemporaryLink,
  updateOrganizationProperties,
} from '@/reducers/organizations/organization.actions'
import {
  createUser,
  exportUsers,
  fetchUserPermissions,
  fetchUsers,
  restoreUser,
  updateUser,
} from '@/reducers/organizations/organizationUsers.actions'
import { ManageSalesforceButton } from '@/views/Organizations/components/buttons/ManageSalesforceButton'
import Select from '@/components/forms/Select'

import { isOrgUnique, setOrgIdInLocalStorage } from '@/components/helpers/tableColumnHelpers'
import { EditableHeader } from './components/EditableHeader'
import UserAuditTable from './components/UserAuditTable'
import UserTable from './components/UserTable'
import { DesktopModeButtonGroup } from './components/buttons/DesktopModeButtonGroup'
import { OrganizationToggleGroup } from './components/OrganizationToggleGroup'
import { DeleteOrganizationButton } from './components/buttons/DeleteOrganizationButton'
import { AuthorizationOptions } from './components/AuthorizationOptions'
import { AddNewUserButton } from './components/buttons/AddNewUserButton'
import { MassPermissionsButton } from './components/buttons/MassPermissionsButton'
import { BulkUserUploadButton } from './components/buttons/BulkUserUploadButton'
import { BulkVoipCampaignUploadButton } from './components/buttons/BulkVoipCampaignUploadButton'
import { ExportUsersButton } from './components/buttons/ExportUsersButton'
import { ManageTagsLink } from './components/ManageTagsLink'
import { ManageVoipCampaignsButton } from './components/buttons/ManageVoipCampaignsButton'
import { CustomizeBrandingButton } from './components/buttons/CustomizeBrandingButton'
import { determineAccessForUser, getUserAdjective } from './helpers'

export const OrganizationPage = ({ flags }) => {
  const dispatch = useDispatch()
  const { users: orgUsers } = useSelector((state) => state.organizationUsers)
  const organizationTags = useSelector((state) => state.organizationTags)
  const organizationTagCategories = useSelector((state) => state.organizationTagCategories)
  const organization = useSelector((state) => state.organization)
  const configs = useSelector((state) => state.configs)
  const { currentlyOpenModalId: modal } = useSelector((state) => state.ui)
  const {
    edit_users: editUsers,
    organizationid: userOrganizationId,
    org_realtime_coaching_access: orgRealtimeCoachingAccess,
  } = useSelector((state) => state.currentUser)

  const [loading, setLoading] = useState(true)
  const [organizationField, setOrganizationField] = useState({})
  const [modalTitle, setModalTitle] = useState({})
  const [userFilter, setUserFilter] = useState('active')
  const history = useHistory()
  const isBaltoAdmin = userOrganizationId === 1
  const isBaltoOrg = organization.id === 1
  const numOfAgents = orgUsers?.filter((user) => !user.password).length
  const numOfManagers = orgUsers?.filter((user) => user.password).length
  const { organizationid } = useParams()

  useEffect(() => {
    dispatch(fetchUserPermissions(userOrganizationId))
  }, [])

  const toggleLoading = () => {
    setLoading((prevLoading) => {
      return !prevLoading
    })
  }

  const handleRestoreUser = (userId) => {
    toggleLoading()
    dispatch(
      restoreUser({
        userId,
        organizationId: organization.id,
        loadUsersData: fetchUsers,
        toggleLoading,
        queryParam: userFilter,
      })
    )
  }

  const handleDeleteOrganization = () => {
    dispatch(
      deleteOrganization({
        organizationId: organization.id,
        push: history.push,
      })
    )
  }

  const handleDeleteDesktopTokens = () => {
    dispatch(deleteDesktopTokens({ organizationId: organization.id }))
  }

  const updateUserFilter = (filter) => {
    setUserFilter(filter)
    dispatch(
      fetchUsers({
        organizationId: organization.id,
        queryParam: filter,
        setLoading,
      })
    )
  }

  const handleUpdateOrganizationProperties = (event, value) => {
    if (event) {
      event.preventDefault()
    }

    toggleLoading()
    dispatch(
      updateOrganizationProperties({
        ...value,
        organizationId: organization.id,
        reloadOrganization: fetchOrganization,
        toggleLoading,
      })
    )
    dispatch(closeModal())
  }

  const handleUpdateDesktopAuthKey = (values) => {
    // Not handling null here because desktop key is required in FE validation. Existing orgs
    // without desktop keys won't be affected but you can not update to remove/nullify an existing key
    handleUpdateOrganizationProperties(null, { desktop_auth_key: trim(values.desktop_auth_key) })
  }

  const handleUpdateSalesforceId = (values) => {
    const updatedSalesforceId = !isEmpty(values.salesforce_id) ? trim(values.salesforce_id) : null

    handleUpdateOrganizationProperties(null, { salesforce_id: updatedSalesforceId })
  }

  const handleUpdateDesktopAuthIPs = (values) => {
    let authIPsList = null

    if (isArray(values.desktop_auth_ips)) {
      authIPsList = values.desktop_auth_ips
    }

    if (isString(values.desktop_auth_ips) && !isEmpty(values.desktop_auth_ips)) {
      authIPsList = values.desktop_auth_ips.split(',').map((ip) => trim(ip))
    }

    handleUpdateOrganizationProperties(null, { desktop_auth_ips: authIPsList })
  }

  const handleUpdateSSO = (values) => {
    handleUpdateOrganizationProperties(null, {
      saml_metadata_url: trim(values.saml_metadata_url),
    })
  }

  const handleGeneratePassphrase = (updatedPassphrase) => {
    dispatch(generatePassphrase(updatedPassphrase))
  }

  const handleGenerateTemporaryLink = (expiration, user) => {
    dispatch(generateTemporaryLink(expiration, user))
  }

  const handleExportUsers = (data) => {
    dispatch(exportUsers({ organizationId: organization.id, data, toggleLoading }))
  }

  const userOptions = [
    { label: 'Active Users', value: 'active' },
    { label: 'Deleted Users', value: 'deleted' },
    { label: 'All Users', value: 'all' },
  ]

  const handleUpdateUser = (user) => {
    user.username = user.username.trim()
    toggleLoading()
    dispatch(
      updateUser({
        userId: user.id,
        userData: user,
        loadUsersData: fetchUsers,
        queryParam: userFilter,
        toggleLoading,
      })
    )
  }

  const handleUpdateUsers = () => {
    setUserFilter('active')
    dispatch(fetchUsers({ organizationId: organization.id, setLoading }))
  }

  const handleCreateUser = (user) => {
    toggleLoading()
    let newUser = { ...user }

    newUser.username = newUser.username.trim()
    newUser.tags = newUser.tags.map((tag) => {
      return tag.id
    })

    newUser = determineAccessForUser(newUser)

    dispatch(
      createUser({
        organizationId: organization.id,
        user: newUser,
        updateUsers: handleUpdateUsers,
        toggleLoading,
      })
    )
  }

  const handleModalChange = (updateOrg, modalTitle) => {
    dispatch(openModal('confirmOrganization'))
    setOrganizationField(updateOrg)
    setModalTitle(modalTitle)
  }

  useEffect(() => {
    // The users view shares this component and the users view does not
    // contain a qs param for the org id so we have to do some gymnastics in
    // order to get the org id for the user view.
    const orgIdToUse = organizationid || userOrganizationId
    dispatch(fetchOrganizationData({ organizationId: orgIdToUse, toggleLoading }))
    dispatch(fetchOrganizationCustomBranding(orgIdToUse))

    if (isOrgUnique(organizationid)) {
      setOrgIdInLocalStorage(organizationid)
    }
  }, [])

  return (
    <>
      {modal === 'confirmOrganization' && (
        <BasicModal
          data-testid="confirm-organization-modal"
          title={modalTitle}
          onClose={() => dispatch(closeModal())}
          show={!!modal}
          size="tiny"
        >
          <p>Are you sure you want to make this change?</p>
          <div className="modal-footer">
            <Button
              secondary
              onClick={() => dispatch(closeModal())}
              data-testid="cancel-change-button"
            >
              Cancel
            </Button>
            <Button
              primary
              onClick={() => handleUpdateOrganizationProperties(null, organizationField)}
              data-testid="confirm-change-button"
            >
              Change
            </Button>
          </div>
        </BasicModal>
      )}
      <div>
        {isBaltoAdmin && loading ? (
          <Segment className="not-added">
            <div className="empty-table">
              <Loader active />
            </div>
          </Segment>
        ) : (
          <>
            <Header className="page-header">
              {isBaltoAdmin ? (
                <EditableHeader
                  orgName={organization.name}
                  updateOrgName={handleUpdateOrganizationProperties}
                />
              ) : (
                <h1 data-testid="org-name-header">{organization.name}</h1>
              )}

              <div>
                <Header.Subheader className="organization-users">
                  {pluralize(`${getUserAdjective(userFilter)} agent`, numOfAgents, true)}
                </Header.Subheader>
                <Header.Subheader className="organization-users">
                  {pluralize(`${getUserAdjective(userFilter)} manager`, numOfManagers, true)}
                </Header.Subheader>
              </div>
            </Header>

            {isBaltoAdmin && (
              <>
                <div
                  className="organization-options-bar ui horizontal segments"
                  data-testid="admin-controls"
                >
                  <DesktopModeButtonGroup
                    alwaysStartCall={organization.desktop_always_start_call}
                    visibility={organization.desktop_visibility}
                    updateOrganizationProperties={handleUpdateOrganizationProperties}
                    handleModalChange={handleModalChange}
                  />
                  <OrganizationToggleGroup
                    production={organization.production}
                    leaderboardVisible={organization.desktop_leaderboard_visibility}
                    isBaltoAdmin={isBaltoAdmin}
                    isBaltoOrg={isBaltoOrg}
                    qaEnabled={organization.qa_enabled}
                    qaCopilotEnabled={organization.qa_copilot_enabled}
                    dataAccessKey={organization.data_access_key}
                    remoteDesktopKey={organization.remote_desktop_key}
                    updateOrganizationProperties={handleUpdateOrganizationProperties}
                    handleModalChange={handleModalChange}
                    rtnEnabled={organization.call_summarization_enabled}
                    showSummarizationNotes={organization.show_summarization_notes}
                    audioExpiryDays={organization.audio_expiry_days}
                  />
                  <DeleteOrganizationButton deleteOrganization={handleDeleteOrganization} />
                </div>
                <AuthorizationOptions
                  desktopAuthKey={organization.desktop_auth_key}
                  desktopAuthIPs={organization.desktop_auth_ips}
                  organizationUuid={organization.uuid}
                  samlMetadataUrl={organization.saml_metadata_url}
                  handleUpdateDesktopAuthIPs={handleUpdateDesktopAuthIPs}
                  deleteDesktopTokens={handleDeleteDesktopTokens}
                  handleUpdateDesktopAuthKey={handleUpdateDesktopAuthKey}
                  handleUpdateSSO={handleUpdateSSO}
                />
              </>
            )}
            <div className="ui menu borderless user-table">
              <div
                className="flex-align-center"
                style={{ margin: '1.5rem' }}
                data-testid="customize-table-container"
              >
                <div className="vertical-center">
                  <Select
                    data-testid="show-users-dropdown"
                    options={userOptions}
                    value={userFilter}
                    onChange={(option) => {
                      updateUserFilter(option.value)
                    }}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className="organization-user-row">
                {(editUsers || isBaltoAdmin) && (
                  <AddNewUserButton
                    configs={configs}
                    tagCategories={organizationTagCategories}
                    createNewUser={handleCreateUser}
                    userOrganizationId={userOrganizationId}
                    orgRealtimeCoachingAccess={orgRealtimeCoachingAccess}
                    orgDesktopEnterpriseEnabled={organization.desktop_enterprise_enabled}
                    tags={organizationTags}
                    qaEnabled={organization.qa_enabled}
                    qaCopilotEnabled={organization.qa_copilot_enabled}
                  />
                )}
                {isBaltoAdmin && (
                  <BulkUserUploadButton
                    tagCategories={organizationTagCategories}
                    setUserFilter={setUserFilter}
                    setLoading={setLoading}
                  />
                )}
                {(editUsers || isBaltoAdmin) && (
                  <MassPermissionsButton
                    orgUsers={orgUsers}
                    organizationId={organization.id}
                    updateUsers={handleUpdateUsers}
                    toggleLoading={toggleLoading}
                  />
                )}
                {isBaltoAdmin && <BulkVoipCampaignUploadButton />}
                {(editUsers || isBaltoAdmin) && (
                  <ExportUsersButton
                    playbooks={configs}
                    exportUsers={handleExportUsers}
                    tags={organizationTags}
                    tagCategories={organizationTagCategories}
                    organization={organization}
                  />
                )}
                <ManageTagsLink toUrl={`${history.location.pathname}/tags`} />
                {isBaltoAdmin && <ManageVoipCampaignsButton />}
                {isBaltoAdmin && (
                  <ManageSalesforceButton
                    handleUpdateSalesforceId={handleUpdateSalesforceId}
                    salesforceId={organization.salesforce_id}
                  />
                )}
                {organization.custom_branding_enabled && isBaltoAdmin && (
                  <CustomizeBrandingButton organizationId={organization.id} />
                )}
              </div>
            </div>

            <UserTable
              loading={loading}
              users={orgUsers}
              configs={configs}
              tags={organizationTags}
              tagCategories={organizationTagCategories}
              editUsers={editUsers}
              isAdmin={isBaltoAdmin}
              qaEnabled={organization.qa_enabled}
              qaCopilotEnabled={organization.qa_copilot_enabled}
              handleUserUpdate={handleUpdateUser}
              generateTemporaryLink={handleGenerateTemporaryLink}
              generatePassphrase={handleGeneratePassphrase}
              orgRealtimeCoachingAccess={orgRealtimeCoachingAccess}
              orgDesktopEnterpriseEnabled={organization.desktop_enterprise_enabled}
              fetchUsers={fetchUsers}
              userFilter={userFilter}
              restoreUser={handleRestoreUser}
              flags={flags}
            />
            <UserAuditTable />
          </>
        )}
      </div>
    </>
  )
}

export default withLDConsumer()(OrganizationPage)
