import React from 'react'
import { Loader, Button } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'

import { SKIP_MODIFIER } from '@/utils/constants'

import { SkipButton } from './components/SkipButton'
import { PlayPauseButton } from './components/PlayPauseButton'
import { PlaybackRateDropdown } from './components/PlaybackRateDropdown'
import { AudioTrackTime } from './components/AudioTrackTime'
import { AudioTrackProgress } from './components/AudioTrackProgress'
import { VolumeSlider } from './components/VolumeSlider'

import { useAudioPlayer } from './AudioPlayerContext'

import './AudioPlayer.scss'

export const AudioPlayer = ({
  loading,
  isPlaying,
  progress,
  duration,
  playbackRate,
  volume,
  audioUrl,
  isDisabled,
  handleScrub,
  handleBackSkip,
  handleForwardSkip,
  handleVolumeChange,
  handlePlaybackRateChange,
  handleCloseDrawer,
  togglePlayState,
  hideAudioTrack,
  hidePlaybackRate,
  hideVolume,
  hideCloseButton,
}) => {
  const { audioRef } = useAudioPlayer()

  return (
    <>
      <audio src={audioUrl} ref={audioRef} />
      <div className="audio-player" data-testid="audio-player">
        {loading ? (
          <Loader inline active />
        ) : (
          <>
            <div className="flex-align-center gap">
              <PlayPauseButton
                isPlaying={isPlaying}
                togglePlayState={togglePlayState}
                isDisabled={isDisabled}
              />
              <div className="flex-align-center small-gap">
                <SkipButton
                  modifier={SKIP_MODIFIER}
                  handleSkip={handleBackSkip}
                  isDisabled={isDisabled}
                />
                <SkipButton
                  modifier={SKIP_MODIFIER}
                  handleSkip={handleForwardSkip}
                  isForwardSkip
                  isDisabled={isDisabled}
                />
              </div>
              {!hideVolume && (
                <VolumeSlider
                  volume={volume}
                  handleVolumeChange={handleVolumeChange}
                  isDisabled={isDisabled}
                />
              )}
            </div>
            <AudioTrackTime duration={duration} progress={progress} divider />
            {!hideAudioTrack && (
              <AudioTrackProgress
                isDisabled={isDisabled}
                duration={duration}
                progress={progress}
                handleScrub={handleScrub}
              />
            )}
            <div className="flex-align-center small-gap">
              {!hidePlaybackRate && (
                <PlaybackRateDropdown
                  isDisabled={isDisabled}
                  handlePlaybackRateChange={handlePlaybackRateChange}
                  playbackRate={playbackRate}
                />
              )}
              {hideCloseButton && (
                <Button
                  compact
                  icon
                  basic
                  type="button"
                  className="svg-button"
                  onClick={handleCloseDrawer}
                >
                  <IconX />
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
