import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { VideoPlayerProvider } from '@/components/video/VideoPlayerContext'

import { MediaPlayer } from '../MediaPlayer'
import { AudioPlayerProvider } from '../AudioPlayerContext'

export const AudioDrawer = () => {
  const { mediaPlayer } = useSelector((state) => state.audio)
  const { isPlaying } = mediaPlayer
  const [isHidden, setIsHidden] = useState(!isPlaying)

  const handleShowPlayer = () => {
    setIsHidden(false)
  }

  const handleHidePlayer = () => {
    setIsHidden(true)
  }

  return (
    <div
      data-testid="audio-drawer"
      className={classNames('bottom-drawer audio-drawer', { hidden: isHidden })}
    >
      <VideoPlayerProvider>
        <AudioPlayerProvider>
          <MediaPlayer
            handleShowPlayer={handleShowPlayer}
            handleHidePlayer={handleHidePlayer}
            hideAudioTrack
            hideVolume
          />
        </AudioPlayerProvider>
      </VideoPlayerProvider>
    </div>
  )
}
