import { toast } from 'react-toastify'
import queryString from 'query-string'

import { fetchingAPI, apiService } from '@/api'
import * as redux from './tasks.redux'

export const fetchTasks = (taskType) => async (dispatch) => {
  try {
    const params = queryString.stringify({
      task_types: taskType,
      task_statuses: ['completed', 'expired', 'ignored', 'ready_for_review'],
    })

    dispatch(redux.setLoading({ [taskType]: true }))
    const response = await fetchingAPI(`${apiService.workflow}/tasks?${params}`, 'GET', dispatch)

    dispatch(redux.setTasks({ [taskType]: response }))
  } catch (err) {
    toast.error('Failed to fetch tasks')
  } finally {
    dispatch(redux.setLoading({ [taskType]: false }))
  }
}

export const manuallyEscalateCall =
  (callId, userId, organizationId, name, reason, timestamp) => async (dispatch) => {
    try {
      const body = {
        name: `Manual escalation for call: ${callId}`,
        call_id: callId,
        agent_id: userId,
        description: reason,
        type: 'compliance',
        status: 'ready_for_review',
        identifier_id: callId,
      }
      if (timestamp) {
        body.manual_timestamp = timestamp
      }
      await fetchingAPI(
        `${apiService.workflow}/tasks?requested_organization_id=${organizationId}`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )
      toast.success('Call escalated')
    } catch (err) {
      toast.error('Failed to escalate call')
    }
  }
