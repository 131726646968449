import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  toggleMediaPlayerPlaying,
  updateMediaPlayer,
  resetMediaPlayer,
  updateSettings,
} from '@/reducers/audio/audio.redux'
import { audioErrorSwitch } from '@/utils/helpers'
import { SKIP_MODIFIER } from '@/utils/constants'

import VideoPlayer from '../video/VideoPlayer'
import { AudioPlayer } from './AudioPlayer'

import { useAudioPlayer } from './AudioPlayerContext'
import { useVideoPlayer } from '../video/VideoPlayerContext'

import './MediaPlayer.scss'

export const MediaPlayer = ({
  isAudioAvailable = true,
  hideAudioTrack,
  hidePlaybackRate,
  hideVolume,
  handleShowPlayer,
  handleHidePlayer,
}) => {
  const dispatch = useDispatch()
  const {
    audioRef,
    jumpAudioTo,
    changeAudioPlaybackRate,
    changeAudioVolume,
    playAudio,
    pauseAudio,
    addAudioEvent,
    removeAudioEvent,
  } = useAudioPlayer()
  const {
    changeVideoPlaybackRate,
    jumpVideoTo,
    playVideo,
    pauseVideo,
    addVideoEvent,
    removeVideoEvent,
  } = useVideoPlayer()
  const { loading, mediaPlayer, settings } = useSelector((state) => state.audio)
  const { progress, duration, audioUrl, audioError, isPlaying, videoUrls, videoError } = mediaPlayer
  const { playbackRate, volume } = settings
  const trackProgressRef = useRef()
  const isDisabled = !audioUrl || audioError
  const isVideoAvailable = !isEmpty(videoUrls) && !videoError

  const setIsPlaying = (value) => {
    if (isPlaying !== value) {
      dispatch(updateMediaPlayer({ isPlaying: value }))
    }
  }

  const setProgress = (value) => {
    dispatch(updateMediaPlayer({ progress: value }))

    if (isAudioAvailable) jumpAudioTo(value)
    if (isVideoAvailable) jumpVideoTo(value)
  }

  const setDuration = (value) => {
    dispatch(updateMediaPlayer({ duration: value }))
  }

  const setVolume = (value) => {
    dispatch(updateSettings({ volume: value }))
    changeAudioVolume(value)
  }

  const setPlaybackRateChange = (value) => {
    dispatch(updateSettings({ playbackRate: value }))

    if (isAudioAvailable) changeAudioPlaybackRate(value)
    if (isVideoAvailable) changeVideoPlaybackRate(value)
  }

  const setErrorState = (value) => {
    audioErrorSwitch(audioRef.current.error.code, audioRef.current.error.message)
    dispatch(updateMediaPlayer({ audioError: value }))
  }

  const togglePlayState = () => {
    dispatch(toggleMediaPlayerPlaying())
  }

  const stopProgressTracking = () => {
    clearInterval(trackProgressRef.current)
    trackProgressRef.current = null
  }

  const startProgressTracking = () => {
    // Clear any timers already running
    stopProgressTracking()

    trackProgressRef.current = setInterval(() => {
      if (audioRef.current) {
        if (audioRef.current.ended) {
          setIsPlaying(false)
        } else {
          dispatch(updateMediaPlayer({ progress: audioRef.current.currentTime }))
        }
      }
    }, [50])
  }

  const play = () => {
    if (isAudioAvailable) playAudio()
    if (isVideoAvailable) playVideo()

    startProgressTracking()
  }

  const pause = () => {
    if (isAudioAvailable) pauseAudio()
    if (isVideoAvailable) pauseVideo()

    stopProgressTracking()
  }

  const handleScrub = (event) => {
    const newTime = event.target.valueAsNumber

    setProgress(newTime)
  }

  const handleForwardSkip = () => {
    const newTime = progress + SKIP_MODIFIER
    const canScrubForward = newTime < duration

    if (canScrubForward) {
      setProgress(newTime)
    } else {
      setProgress(duration)
    }
  }

  const handleBackSkip = () => {
    const newTime = progress - SKIP_MODIFIER
    const canScrubBack = newTime > 0

    if (canScrubBack) {
      setProgress(newTime)
    } else {
      setProgress(0)
    }
  }

  const handlePlaybackRateChange = (event, option) => {
    const updatedPlaybackRate = parseFloat(option.value)

    localStorage.setItem('audioPlaybackRate', updatedPlaybackRate)
    setPlaybackRateChange(updatedPlaybackRate)
  }

  const handleVolumeChange = (value) => {
    setVolume(value)
  }

  const handleCloseDrawer = () => {
    if (handleHidePlayer) handleHidePlayer()
    dispatch(resetMediaPlayer())
    stopProgressTracking()
  }

  useEffect(() => {
    // Show player, play, and set duration if playing
    if (isPlaying && audioUrl) {
      if (handleShowPlayer) handleShowPlayer()
      if (progress === duration) {
        // Set the progress to the beginning if you try to play after the audio has ended.
        // This ensures it doesn't just start for a few milliseconds, go to the beginning, then pause.
        setProgress(0)
      }
      play()
    }

    // Pause if audio is loaded but not playing
    if (!isPlaying) {
      pause()
    }
  }, [isPlaying])

  useEffect(() => {
    const durationListener = (event) => {
      setDuration(event.target.duration)
    }
    const errorListener = () => {
      setErrorState(true)
    }

    addAudioEvent('loadedmetadata', durationListener)
    addAudioEvent('error', errorListener)

    if (!isAudioAvailable && isVideoAvailable) {
      addVideoEvent('loadedmetadata', durationListener)
    }

    const storedPlaybackRate = localStorage.getItem('audioPlaybackRate')
    if (storedPlaybackRate) {
      setPlaybackRateChange(parseFloat(storedPlaybackRate))
    }

    return () => {
      removeAudioEvent('loadedmetadata', durationListener)
      removeAudioEvent('error', errorListener)

      if (!isAudioAvailable && isVideoAvailable) {
        removeVideoEvent('loadedmetadata', durationListener)
      }

      stopProgressTracking()
    }
  }, [audioUrl])

  const videoOnPlaying = () => setIsPlaying(true)
  const videoOnPause = () => setIsPlaying(false)
  const videoOnSeeked = (event) => {
    const { currentTime } = event.target
    dispatch(updateMediaPlayer({ progress: currentTime }))

    if (isAudioAvailable) jumpAudioTo(currentTime)
    // Don't jump to video because the built-in fullsceen controls already do it.
  }

  return (
    <>
      {isVideoAvailable && (
        <div className="video-player-container">
          <VideoPlayer
            isPlaying={isPlaying}
            progress={progress}
            playbackRate={playbackRate}
            sources={videoUrls}
            onPlaying={videoOnPlaying}
            onPause={videoOnPause}
            onSeeked={videoOnSeeked}
          />
        </div>
      )}
      <div className="audio-player-container">
        <AudioPlayer
          loading={loading.mediaPlayerByCallId}
          isPlaying={isPlaying}
          duration={duration}
          progress={progress}
          playbackRate={playbackRate}
          volume={volume}
          audioUrl={audioUrl}
          isDisabled={isDisabled}
          handleScrub={handleScrub}
          handleBackSkip={handleBackSkip}
          handleForwardSkip={handleForwardSkip}
          handleVolumeChange={handleVolumeChange}
          handlePlaybackRateChange={handlePlaybackRateChange}
          handleCloseDrawer={handleCloseDrawer}
          togglePlayState={togglePlayState}
          hideAudioTrack={hideAudioTrack}
          hidePlaybackRate={hidePlaybackRate}
          hideVolume={hideVolume}
          hideCloseButton={handleHidePlayer}
        />
      </div>
    </>
  )
}
