import { toast } from 'react-toastify'

import { apiService, fetchingAPI } from '@/api'

import * as redux from './audio.redux'

export const fetchVideoForCallId = (callId) => async (dispatch) => {
  try {
    const { video_addresses: videoUrls, video_file_status: videoStatus } = await fetchingAPI(
      `${apiService.reporting}/api/calls/${callId}/screen_capture`,
      'GET',
      dispatch
    )

    if (videoStatus === 'available') {
      dispatch(
        redux.updateMediaPlayer({
          callId,
          videoUrls,
          videoStatus,
        })
      )
    }
  } catch {
    // Fail silently for now
    // console.error('Failed to fetch screen capture for call')
    // dispatch(redux.updateMediaPlayer({ videoError: true }))
  }
}

export const fetchAudioForCallId = (callId) => {
  return async (dispatch) => {
    dispatch(redux.resetMediaPlayer())
    dispatch(redux.setLoading({ mediaPlayerByCallId: callId })) // set the ID here so it shows the loading state on the right one

    try {
      const {
        audio_address: url,
        audio_file_status: audioStatus,
        expiration_seconds: audioUrlExpiration,
      } = await fetchingAPI(`${apiService.reporting}/api/calls/${callId}/audio`, 'GET', dispatch)

      if (audioStatus === 'available') {
        dispatch(
          redux.updateMediaPlayer({
            callId,
            audioUrl: url,
            audioUrlExpiration,
            audioStatus,
            isPlaying: true,
          })
        )
      } else {
        toast.error('Failed to fetch audio for call')
      }

      // should have a check here to see if video is available before trying to fetch
      await dispatch(fetchVideoForCallId(callId))
    } catch (err) {
      dispatch(redux.updateMediaPlayer({ audioError: true }))
      toast.error('Failed to fetch audio for call')
    } finally {
      dispatch(redux.setLoading({ mediaPlayerByCallId: null }))
    }
  }
}
