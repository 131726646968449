import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import { Field, Formik } from 'formik'

import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'

import { closeModal } from '@/reducers/ui/ui.redux'
import { manuallyEscalateCall } from '@/reducers/tasks/tasks.actions'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { NumberField, TextArea, TextField } from '@/components/forms/formik'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  reason: Yup.string().required('Reason is required.'),
  minutes: Yup.number().min(0, 'Enter a valid number'),
  seconds: Yup.number().min(0, 'Enter a valid number').max(59, 'Seconds cannot be greater than 59'),
})

export const ManualEscalationModal = ({ callId, userId, organizationId }) => {
  const dispatch = useDispatch()

  const saveEscalation = ({ name, reason, minutes, seconds }) => {
    let timestamp

    if (minutes || seconds) {
      timestamp = 0
    }
    if (minutes) {
      timestamp += minutes * 60
    }
    if (seconds) {
      timestamp += seconds
    }

    dispatch(manuallyEscalateCall(callId, userId, organizationId, name, reason, timestamp))
    dispatch(closeModal())
  }

  return (
    <ButtonAndFormModal
      buttonProps={{ compact: true, secondary: true }}
      data-testid="manual-escalation"
      buttonLabel="Manual Escalation"
      modalTitle={`Manual Escalation for ${callId}`}
      modalId="manual-escalation"
      form={
        <Formik
          validateOnMount
          initialValues={{ reason: '', name: `Manual escalation for call: ${callId}` }}
          validationSchema={validationSchema}
          onSubmit={saveEscalation}
        >
          {({ handleSubmit, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Field name="name" component={TextField} placeholder="Name" label="Name" />
                <Field
                  name="reason"
                  component={TextArea}
                  placeholder="Please write a short reason for manual escalation"
                  label="Reason"
                />
                <div className="flex gap mb">
                  <Field
                    className="flex-column"
                    style={{ width: '100px' }}
                    name="minutes"
                    component={NumberField}
                    label="Minutes"
                  />
                  <Field
                    className="flex-column"
                    style={{ width: '100px' }}
                    name="seconds"
                    component={NumberField}
                    label="Seconds"
                  />
                </div>
                <div className="modal-footer">
                  <Button
                    secondary
                    data-testid="cancel-manual-escalation"
                    onClick={() => {
                      dispatch(closeModal())
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="save-manual-escalation"
                    primary
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isEmpty(errors)}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      }
    />
  )
}
