import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Loader } from 'semantic-ui-react'
import { IconDotsVertical, IconSparkles, IconSettings } from '@tabler/icons-react'
import { isEmpty } from 'lodash'

import { fetchTasks } from '@/reducers/tasks/tasks.actions'
import {
  COACHING_TASK_TYPE,
  COMPLETED,
  EXPIRED,
  IGNORED,
  READY_FOR_REVIEW,
} from '@/reducers/tasks/tasks.constants'
import { InboxAccordion } from '@/components/inbox/components/InboxAccordion'
import { TaskCard } from '@/components/inbox/components/TaskCard'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'

export const CoachingInbox = () => {
  const dispatch = useDispatch()
  const [taskType, setTaskType] = useState('')
  const { loading, tasks } = useSelector((state) => state.tasks)
  const coachingTasks = tasks[COACHING_TASK_TYPE] || []
  const coachingLoading = loading[COACHING_TASK_TYPE] || false
  const todoTasks = coachingTasks.filter((task) => task.status === READY_FOR_REVIEW)
  const archivedTasks = coachingTasks.filter((task) =>
    [IGNORED, EXPIRED, COMPLETED].includes(task.status)
  )
  const filteredArchivedTasks = archivedTasks.filter((task) =>
    taskType ? task.status === taskType : true
  )

  const taskTypeOptions = [
    { label: 'Ignored', value: 'ignored' },
    { label: 'Completed', value: 'completed' },
    { label: 'Expired', value: 'expired' },
  ]

  const handleSecondaryAction = () => {
    console.info('Secondary action clicked')
  }

  useEffect(() => {
    dispatch(fetchTasks(COACHING_TASK_TYPE))
  }, [])

  return (
    <div>
      <header className="page-header">
        <h1>Coaching Opportunities</h1>
        <Button secondary icon className="svg-button">
          <IconSettings />
          Settings
        </Button>
      </header>

      {coachingLoading ? (
        <Loader active />
      ) : (
        <>
          <InboxAccordion
            title="To Do"
            actions={
              <>
                <Button basic icon compact className="svg-button">
                  <IconDotsVertical />
                </Button>
                <Button secondary icon compact className="svg-button">
                  <IconSparkles className="svg-icon status-magical" />
                  Generate More
                </Button>
              </>
            }
          >
            {todoTasks.map((task) => (
              <TaskCard
                key={task.id}
                {...task}
                title={task.name}
                handleSecondaryAction={handleSecondaryAction}
              />
            ))}
          </InboxAccordion>

          <InboxAccordion title="Archive" muted defaultExpanded={false}>
            <FilterTypeSelect
              showAllOnEmpty
              schema={{
                accessor: 'organizationId',
                label: 'Archived Tasks',
                options: taskTypeOptions,
              }}
              value={taskType}
              updateSearch={(accessor, value) => {
                console.info(accessor, value)
                setTaskType(value)
              }}
            />

            {isEmpty(filteredArchivedTasks)
              ? 'No tasks match the filter'
              : filteredArchivedTasks.map((task) => (
                  <TaskCard
                    key={task.id}
                    {...task}
                    title={task.name}
                    handleSecondaryAction={handleSecondaryAction}
                  />
                ))}
          </InboxAccordion>
        </>
      )}
    </div>
  )
}
